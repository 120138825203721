<template>
  <div class="ly_news_cb_main">
      <div class="ly_news_cb_title">STR鉴定</div>
      <p>细胞STR鉴定应用于生物医学研究领域的哺乳动物细胞被错误鉴定和交叉污染的问题，一直是一个普遍存在的突出问题。 </p>
      <p>据统计，国外实验室有20%左右的细胞株被错误鉴定和交叉污染，NIH和ATCC近两年都对此发出呼吁，要求研究者对细胞进行鉴定。近年来，大量研究表明STR基因分型方法是进行细胞交叉污染和性质鉴定的最有效和准确的方法之一，STR基因分型应用于细胞鉴定已被ATCC等机构强烈推荐。美国的ATCC细胞库、德国的DSMZ细胞库以及日本的JCRB细胞库等为STR分型提供了各细胞株的数据供比对。</p>
      <p>STR（Short Tandem Repeat，短串联重复序列）基因位点由长度为3-7个碱基对的短串连重复序列组成，这些重复序列广泛存在于人类基因组中，可作为高度多态性标记，被称为细胞的DNA指纹，其可通过PCR（聚合酶链式反应）来检测。STR基因座位上的等位基因可通过扩增区域内重复序列的拷贝数的不同来区分，在毛细管电泳分离之后可通过荧光检测来识别。随后通过一定的计算方法，即可根据所得的STR分型结果与专业的细胞STR数据库比对从而推算出样品所属的细胞系或可能的交叉污染的细胞系名称。</p>
      <p>我们为进一步提高鉴定的分辨率，除了包含ATCC检测的8个STR和1个性别决定位点Amelogenin外，另新增了12个高度多态性位点，共检测21个STR位点。</p>

      <div class="ly_news_cb_hot_so">送鉴定样本要求：</div>
      <p>细胞样本：一瓶活细胞 ,细胞数≥106 cells ;冻存细胞,细胞数≥106 cells ;细胞沉淀,细胞数≥106 cells</p>

      <p>DNA样本：OD 260/OD 280=1.8-2.0 ; DNA浓度≥50 ng /μL , DNA体积≥20 μL (冻存细胞,细胞沉淀,DNA样本,请低温运输! )</p>

      <p>客户需要提供细胞样品，管壁标记清楚细胞的名称，数量较多时要标好标注，并附送鉴清单。</p>
      <p>每次鉴定的周期为1周，具体细节请与相关客服人员沟通好。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #df0024">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "StrAppraisal",
  setup() {
    const router = useRouter();
    function onBack() {
      router.back()
    }
    return {
      onBack
    };
  },
});
</script>


<style lang="scss" scoped>
.ly_news_cb_main {
  padding: 15px;
  font-size: 0.42667rem;
  line-height: 0.66667rem;
  color: #666;
  padding-bottom: 1.33333rem;
}

p {
  margin: 1em 0;
  word-break: break-word;
  text-indent: 20px;
}

.ly_news_cb_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

.ly_news_cb_hot_so {
  font-weight: 600;
  color: #444;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}
.download {
  float: right;
}
</style>